.container_filtros {
  margin: 5px 15px;
}

.filtro_grid {
  display: grid;
  // grid-template-columns: 4fr;
  // gap: 5px;
  align-items: center;
  padding: 5px 1.5rem 1.1rem 1.5rem;

  &_inputs_grid {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    // grid-template-columns: repeat(auto-fill,      minmax(340px, 1fr)    ); // comentado porque rompe el gridarea
    // grid-auto-rows: 150px;
    //gap: 2.5px;
    row-gap: 1rem;
    align-items: start;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  &_int {
    display: "grid";
    grid-column: "1/-1";
  }

  &_fecha {
    display: flex;
    //flex-direction: column;
    //justify-content: space-between;
  }
  &_radio_button {
    margin-left: 5px;
    margin-top: 4px;
    &_label {
      font-size: medium;
      font-weight: lighter;
    }

    .MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }
  &_fecha {
    .filtro_fecha_input {
      &_error {
        border: solid 2px red;
      }
    }
  }
}

.fecha_input {
  width: 90%;
}

@media only screen and (max-width: 640px) {
  .filtro_grid_inputs_grid {
    display: inline;
  }
  .filtro_grid_radio_button_label {
    margin-top: 0.85rem;
  }
}

.MuiButtonBase-root {
  min-height: 0 !important;
  margin: 0;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

button.InputBusquedaLupa {
  padding: 1.2em 0;
  background-color: #e7e7e7!important;
  color: #006f7a !important;
  width: 12% !important;
  min-height: max-content;
  border-radius: 8px;
}
.filtroBuscador {
  width: 100%;
}
.filtroBuscador,
.likeComponente,
.filtroFecha,
.filtroSelect {
  .MuiInputLabel-formControl {
    transform: translate(0, 9px) scale(1);
  }

  .MuiInputLabel-shrink {
    transform: translate(0, -5px) scale(0.75);
  }

  label + .MuiInput-formControl {
    margin-top: 0;
  }
}
