@import "../../components/Pantalla.scss";

.novedades_lista {
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 2px;
    color: blue;
  }
  .MuiIconButton-root {
    &:hover {
      background-color: rgba(32, 168, 216, 0);
    }
  }
  .MuiTableCell-root.MuiTableCell-body {
    height: 2em;
  }
  .mostrarFilter {
    margin-left: 1rem;
    margin-top: 0.9rem;

    @media screen and (min-width: 800px) {
      margin-top: 0;
    }
    &_select {
      min-width: 286px;
      @media screen and (min-width: 800px) {
        min-width: 0;
        width: 100%;
      }
    }
  }
  [class*="MTableToolbar-spacer"] {
    display: none;
  }
  [class*="MTableToolbar-searchField"] {
    align-self: flex-start;
    margin: 0.7rem;
  }
  [class*="MTableToolbar-actions"] {
    align-self: flex-start;
    > div > div {
      > span {
        display: grid;
        margin-top: 1rem;
        @media screen and (min-width: 800px) {
          grid-template-columns: 1fr 1fr 1fr;
          width: 100%;
          margin-top: 1rem;
        }
        @media screen and (min-width: 1100px) {
          grid-template-columns: 1fr 1fr 1fr;
          margin-top: 0;
        }
      }
    }
  }
  [class*="MTableToolbar-searchField"] {
    padding-left: 0;
    top: 3px;
    width: 40%;
    position: absolute;
    @media screen and (max-width: 640px) {
      // flex-direction: column;
      width: 100%;
    }
  }
  .MuiToolbar-root {
    @media screen and (max-width: 640px) {
      // flex-direction: column;
    }
  }
  .MuiToolbar-gutters {
    padding: 0;
    padding-top: 0rem;
    margin-bottom: 0rem;
    justify-content: flex-end;
  }
}

.listado_card_body > :not(.container_filtros) > div > div > div {
  overflow-y: hidden !important;
}

._textFieldEditable {
  > div {
    padding: 0;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.87) !important;

    display: flex;
    justify-content: center;
    align-items: center;

    > fieldset {
      bottom: -2px;
      top: -3px;
    }
  }

  &.align-center {
    > div {
      > textarea {
        text-align: center;
        margin-right: 5px;
      }
    }
  }
  &.align-right {
    > div {
      > textarea {
        text-align: right;
        margin-right: 5px;
      }
    }
  }
}

.listado-desaparecer {
  animation-name: desaparecer;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes desaparecer {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.MuiTableRow-root:hover {
  opacity: 1 !important; // el opacity original me rompe el tooltip
  background-color: rgba(240, 248, 255, 0.9);
}

// span.Ftooltip {
//   &::after,
//   &::before {
//     z-index: 90;
//     content: " ";
//     position: absolute;
//     bottom: 100%; /* At the top of the tooltip */
//     left: 50%;
//     margin-left: -5px;
//     border-width: 4px;
//     border-style: solid;
//     border-color: transparent transparent rgba(90, 90, 90, 1) transparent;
//   }
//   display: block;
//   position: absolute;
//   background-color: rgba(90, 90, 90, 1);
//   color: white;
//   padding: 0.7rem;
//   left: 50%;
//   transform: translateX(-50%);
//   z-index: 100;
//   border-radius: 20px;
//   margin-top: 6.5px;
//   &_verde {
//     &::after,
//     &::before {
//       border-color: transparent transparent limegreen transparent;
//     }
//   }
//   &_rojo {
//     &::after,
//     &::before {
//       border-color: transparent transparent tomato transparent;
//     }
//   }
// }

.Listado_Switch_Enlace {
  padding: 0.35rem 0.8rem;
  border-radius: 5px;
  margin: 0.2rem;
  background-color: lightgray;
  font-weight: bold;
  width: fit-content;

  a {
    text-decoration: none;
    color: rgb(33, 37, 41);
  }

  &:hover {
    background-color: $primary-hover; //boton Ver Tratamiento
    a {
      color: black;
    }
    cursor: pointer;
  }
}

.btn-orixia {
  padding: 0.35rem 0.8rem;
  border-radius: 5px;
  margin: 0.2rem;
  background-color: lightgray;
  font-weight: bold;

  a {
    text-decoration: none;
    color: rgb(33, 37, 41);
  }

  &:hover {
    background-color: $primary-hover; //boton Ver Tratamiento
    a {
      color: black;
    }
    cursor: pointer;
  }
}
.MuiTableCell-head {
  width: unset !important;
}
.icono-orixia {
  font-size: 1rem !important;
  color: #006f7a !important;
  width: min-content;
  a {
    color: #006f7a;
    text-decoration: none;
    background-color: transparent;
  }
  &:hover {
    opacity: 0.8;
    a {
      background-color: $primary-hover; //boton Ver Tratamiento
    }
    cursor: pointer;
  }
}

[data-rfd-drag-handle-context-id="0"],
[data-rfd-drag-handle-context-id="1"] {
  justify-content: center;
}

.input-disabled {
  /* Deshabilitar interacción con el input */
  pointer-events: none;
  /* Cambiar el color de fondo y el color de texto */
  background-color: -internal-light-dark(
    rgba(239, 239, 239, 0.3),
    rgba(59, 59, 59, 0.3)
  );
  color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
  /* Cambiar el estilo del cursor */
  cursor: not-allowed;
  /* Opcional: Aplicar un estilo visual para indicar que está desactivado */
  opacity: 0.6;
  border-color: rgba(118, 118, 118, 0.3);
  border: none;
}

.swal2-html-container {
  overflow: hidden;
  & .spinner {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    border-radius: 50%;
    border: 3px solid #f3f3f3;
    border-top-color: #3498db;
    animation: spinner 0.8s linear infinite;
  }
}

/* Animación del spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
