// Your variable overrides
$primary: #006f7a;
$blue: #006f7a;
$link-hover-decoration: none;

$primary-hover: #006f7a7a;

// Bootstrap and its default variables
@import "../../../../node_modules/bootstrap/scss/bootstrap";

#SISTEMA_MAIN {
  max-width: 100vw;
}

.debugger_consola {
  // position: "fixed" !important;
  // top: 0 !important;
  // background: "white" !important;
  // height: "15vh" !important;
  // max-height: "50vh" !important;
  // overflow-y: "scroll" !important;
  // resize: "vertical" !important;
  // z-index: "400" !important;
  // margin-top: "50px" !important;
  // border-bottom: "1px black solid" !important;
  // width: "fit-content" !important;
}
.debuger_dragable {
  @media (max-width: 768px) {
    display: none; // Oculta el elemento en pantallas de celular
  }
}
.switch_abm_input_base {
  @media (max-width: 720px) {
    grid-column: 1/10 !important;
  }
}

.modalContainerStyle {
  top: 150px;
  left: 250px;
  width: max-content;
  max-width: calc(100% - 400px);
  margin: 0 calc((100% - 400px) / 20);
  padding: 5px;
  background: white;
  border-radius: 15px;
  transform: translate(0%, 0%);
}

body {
  // zoom:80%;
}

.app-body {
  margin-top: 0;
}

.bg-debug {
  border: 1px solid black;
  background-color: #add8e6 !important;
}

.swal2-container {
  z-index: 999999; /* Establece el valor deseado para el z-index */
}

.configuracion_pantalla_titulo {
  width: 100%;
  text-align: center;
  background-color: #006f7a;
  color: rgb(247, 247, 247);
  min-height: 1.75rem;
  //max-height: 1.5rem;

  &_principal {
    font-size: 1.3rem;
  }
  &_secundario {
    font-size: 1.2rem;
  }
  p {
    margin: 0;
  }
}

span.Ftooltip {
  &::after,
  &::before {
    z-index: 90;
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(90, 90, 90, 1) transparent;
  }
  // top: 1rem;
  cursor: help;
  display: block;
  position: fixed;
  background-color: rgba(90, 90, 90, 1);
  color: white;
  padding: 0.7rem;
  // left: min(50%);
  transform: translateX(-50%);
  z-index: 100;
  border-radius: 1.5em;
  margin-top: 0.4em;
  min-width: 6em;
  max-width: 18em;
  text-align: center;

  opacity: 0;
  transition: opacity 10s ease-in-out;
  &[aria-hidden="false"] {
    opacity: 1;
    pointer-events: auto;
  }

  &_okey {
    &::after,
    &::before {
      border-color: transparent transparent limegreen transparent;
    }
  }
  &_danger {
    &::after,
    &::before {
      border-color: transparent transparent tomato transparent;
    }
  }
}

div[id$="menu_vista"] {
  width: 100%;
  .flex_acciones_vista {
    display: flex;
    justify-content: flex-end;
    margin-right: 1.45em;
    position: relative;
    bottom: 2.25px;
    padding-top: 5px;
    font-size: 1em;
    width: 100%;
    @media print {
      display: none;
    }
    .flex_acciones_vista {
      &_imprimir,
      &_email,
      &_historial,
      &_excel,
      &_configuracion {
        //font-size: 0.8em;
        color: #ffffff; // whitesmoke;
        padding: 0 5px;
        background-color: #006f7a;
      }
      &_alt_imprimir,
      &_alt_email,
      &_alt_historial,
      &_alt_excel,
      &_alt_configuracion {
        //font-size: 0.8em;
        width: min-content;
        color: #006f7a !important;
        padding: 0 5px;
        a {
          color: #006f7a !important;
        }
      }
    }
  }
  &.menu_vista_no_listado {
    position: absolute;
    height: 100%;
    top: 50%;
    right: 0;
    // border: solid 3px red;
    transform: translate(0%, -50%);
    max-height: 1.65rem;
    width: 200px;
    margin: 0 15px;
    @media print {
      display: none;
    }
    z-index: 100;
  }
  &.menu_vista_no_titulo {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 5px 0 0 5px;
  }
  &.menu_listado_no_titulo {
    position: relative !important;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &.menu_listado {
    // transform: translate(0%, -50%);
    max-height: 1.65rem;
    margin: 0 15px;
    @media print {
      display: none;
    }
    // &.subir_acciones {
    //   top: -1.875rem;
    //   position: relative;
    // }
    .flex_acciones_vista {
      &_imprimir,
      &_email,
      &_historial,
      &_excel,
      &_configuracion {
        //font-size: 0.8em;
        color: #006f7a; // whitesmoke;
        padding: 0 5px;
        background-color: rgba(255, 255, 255, 0);
      }
      &_alt_imprimir,
      &_alt_email,
      &_alt_historial,
      &_alt_excel,
      &_alt_configuracion {
        //font-size: 0.8em;
        width: min-content;
        color: #006f7a !important;
        padding: 0 5px;
        a {
          color: #006f7a !important;
        }
      }
    }
  }
}

.link_historial,
.link_historial:link,
.link_historial:visited,
.link_historial:hover,
.link_historial:active {
  color: white;
}

.pantalla-fondo-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(128, 128, 128, 0.4);
  z-index: 1000;
}

.pantalla-modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // z-index: 1020;
  width: 650px;
  max-width: 100%;
  padding: 10px;
}

textarea.textArea_disabled {
  border: 1px solid rgb(227, 224, 224) !important;
  background-color: rgb(227, 224, 224);
}
.textField_disabled {
  border: 1px solid rgb(227, 224, 224) !important;
  background-color: rgb(227, 224, 224);
}

.conUpdateId {
  border-color: darkgreen;
  border-width: 3px;
}

.sinUpdateId {
  border-color: gray;
}

.swal2-container {
  z-index: 3000 !important;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
  font-size: 1rem;
  color: #006f7a;
}

.vibrar {
  transform-origin: 0% 0%;
  -webkit-animation: vibrate-1 0.3s linear infinite both;
  animation: vibrate-1 0.3s linear infinite both;
}

.maquinaescribir {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

.orixia-btn {
  padding: 0.35rem 0.8rem;
  border-radius: 5px;
  margin: 0.2em;
  background-color: lightgray;
  font-weight: bold;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #4f4f4f;
  width: fit-content;

  a {
    text-decoration: none;
    color: #4f4f4f;
    /* &:hover {
      background-color: #006f7a7a;
      cursor: pointer;
      col or: black;
    }*/
  }
  &:hover {
    background-color: $primary-hover;
    cursor: pointer;
    color: black;
  }
}

.no-imprimir {
  @media print {
    visibility: hidden;
    display: none;
  }
}

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

@media print {
  * {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f1f1f1;
}

.spinner-container i {
  font-size: 50px;
  color: #1abc9c;
}

.fondo-rojo {
  color: #fff;
  padding: 4px 4px;
  text-align: left;
  background-color: #f86c6b;
  width: fit-content;
  border-radius: 5px;
  a {
    color: #fff;
    text-align: left;
    padding: 4px 4px;
  }
}

.fondo-verde {
  color: #fff;
  padding: 4px 4px;
  text-align: left;
  background-color: #4dbd74;
  width: fit-content;
  border-radius: 5px;
  a {
    color: #fff;
    text-align: left;
    padding: 4px 4px;
  }
}

.fondo-amarillo {
  color: #4a4a4a;
  padding: 4px 4px;
  text-align: left;
  background-color: #ffc107;
  width: fit-content;
  border-radius: 5px;
  a {
    color: #fff;
    text-align: left;
    padding: 4px 4px;
  }
}

.fondo-violeta {
  color: #fff;
  padding: 4px 4px;
  text-align: left;
  background-color: #454795;
  width: fit-content;
  border-radius: 5px;
  a {
    color: #fff;
    text-align: left;
    padding: 4px 4px;
  }
}

.fondo-naranja {
  color: #fff;
  padding: 4px 4px;
  text-align: left;
  background-color: #f78900;
  width: fit-content;
  border-radius: 5px;
  a {
    color: #fff;
    text-align: left;
    padding: 4px 4px;
  }
}

.fondo-celeste {
  color: #fff;
  padding: 4px 4px;
  text-align: left;
  background-color: #45aee1;
  width: fit-content;
  border-radius: 5px;
  a {
    color: #fff;
    text-align: left;
    padding: 4px 4px;
  }
}

.fondo-turquesa {
  color: #fff;
  padding: 4px 4px;
  text-align: left;
  background-color: #328b94;
  width: fit-content;
  border-radius: 5px;
  a {
    color: #fff;
    text-align: left;
    padding: 4px 4px;
  }
}

.fondo-gris {
  color: #fff;
  padding: 4px 4px;
  text-align: left;
  background-color: #777777;
  width: fit-content;
  border-radius: 5px;
  a {
    color: #fff;
    text-align: left;
    padding: 4px 4px;
  }
}

.letra-roja {
  color: #d54241;
  font-weight: bolder;
}
.letra-amarilla {
  color: #ffc107;
  font-weight: bolder;
}
.letra-verde {
  color: #4dbd74;
  font-weight: bolder;
}
.letra-violeta {
  color: #454795;
  font-weight: bolder;
}
.letra-naranja {
  color: #f78900;
  font-weight: bolder;
}
.letra-celeste {
  color: #45aee1;
  font-weight: bolder;
}
.letra-turquesa {
  color: #328b94;
  font-weight: bolder;
}
.letra-negra {
  color: #000000;
  font-weight: bolder;
}
.letra-gris {
  color: #777777;
  font-weight: bolder;
}

.class_no_wrap {
  white-space: nowrap;
}

.wsp_div {
  display: flex;
  justify-content: space-around;
  flex-wrap: none;
  width: max-content;
  .wsp_icon {
    width: 10%;
    color: #24c862;
    font-size: 1.5rem;
  }
  .wsp_div_texto {
    display: flex;
    align-items: center;
    width: 90%;
  }
}

.input_fecha {
  border-radius: 5px;
  text-align: center;
  width: 80px;
  border: none;
}

.tarjeta_grid_item_componente_campo_mapa {
  // width: 100px;
  & > div {
    width: 100%;
  }
}

.map_container {
  height: 100%;
  width: 100%;
  //  border: 1px solid blue;
  position: relative;

  & > div {
    max-height: 100%;
    max-width: 100%;
    //    border: 2px solid red;
  }
}
