.tarjeta {
  display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 1em;
  padding: 0rem 0.45rem 0.75rem 0.3rem;
  font-size: 0.85em;
  &_titulo {
    grid-column: 1/-1;
    margin: 0;
  }
  &_grid_item {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    // flex-direction: column;
    padding: 0.3rem 0.75rem;
    height: auto;
    // border-radius: 0.45rem;
    // box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    //   rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    //   rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

    &_componente_campo {
      display: flex;
      margin: 8px 0 6px 0;
    }
    &_label_item {
      display: flex;
      width: 100%;
      align-items: center;
      //margin-left: 0.5rem;
    }
    &_input_editable {
      min-height: 26px;
      width: 100%;
      border-radius: 0.225rem;
    }
    &_select {
      height: fit-content;
      margin-left: 4.5px;
    }
    .MuiFormControlLabel-label {
      font-size: 1em;
    }
    .MuiRadio-root {
      // transform:scale(0.8);
    }
    label {
      margin-bottom: 0;
    }
    .divCampo {
      @media (max-width: 720px) {
        grid-column: 1/-1 !important;
      }
    }
  }
  .borde-tarjeta {
    border-radius: 0.45rem;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
      rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
      rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  }
}

.grupo_columnas_m_p {
  margin: 0 -0.75rem;
  padding: 0 0.75rem;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.checkbox_tarjeta {
  font-weight: bold;
  word-break: break-all;
  margin-left: 5.5px;
}

.badge-danger {
  font-size: small;
}
