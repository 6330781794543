.abm {
  &_botonera {
    padding: 0 0 1rem 3rem;
    & #abm_boton_guardar {
      // background-color: green
    }
  }
  &_campos {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 5px;
   // gap: 1.5rem;
    row-gap: 1rem;
    align-items: center;
    padding: 1.5rem 3rem;
    border: none;
    &_inputs_grid {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 1.5rem;
      row-gap: 20px;
    }
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 7px;
    height: 16px !important;
    overflow-y: hidden;
  }
}
