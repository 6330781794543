.vista_label {
  margin-right: 4.5px;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: center;
  // &_fuente {
  //   //font-weight: bold;
  //   max-width: 85px;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  //   word-break: break-all;
  //   overflow: hidden;
  // }
  &_select {
    font-weight: bold;
    display: flex;
    width: 100%;
  }
}

.suW {
  width: max-content;
}

.Vista_Switch_Enlace {
  padding: 0.3rem 0.5rem;
  border: 1px black solid;
  margin: 0.2rem;
  background-color: lightgray;
  width: fit-content;

  a {
    text-decoration: none;
    color: rgb(79, 79, 79);
  }

  &:hover {
    background-color: lightblue;
    a {
      color: black;
    }
    cursor: pointer;
  }
}

.pointer {
  text-decoration: none; /* Quita el subrayado predeterminado del enlace */
  color: #52AEEB; /* Cambia el color del texto del enlace a azul (#007bff), puedes ajustar este valor según tus preferencias */
  cursor: pointer;
  &:hover {
    text-decoration: underline; /* Añade subrayado al pasar el cursor sobre el enlace */
    color: #0056b3;
  }
  &:visited {
    color: #9400d3; /* Cambia el color del texto del enlace a violeta (#9400d3) cuando ha sido visitado, puedes ajustar este valor según tus preferencias */
  }
}
